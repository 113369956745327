<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/sales/purchases')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-4">
        <div class="mb-3">
          <label for="date" class="form-label required">
            التاريخ
            <span>*</span>
          </label>

          <Calendar
            style="width: 100%;"
            v-model="body.date"
            :class="{ 'is-invalid': isInvalid && !body.date }"
            dateFormat="dd-mm-yy"
            :minDate="minDateValue"
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="taskTime" class="form-label required">
            الوقت
            <span>*</span>
          </label>
          <Calendar
            style="width: 100%;"
            v-model="body.taskTime"
            :class="{ 'is-invalid': isInvalid && !body.taskTime }"
            :timeOnly="true"
            hourFormat="12"
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="warehouseId" class="form-label required">
            المستودع
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="warehouseId"
            name="warehouseId"
            v-model.number="body.warehouseId"
            :class="{ 'is-invalid': isInvalid && !body.warehouseId }"
            required
          >
            <option
              v-for="item of warehouseList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="clientsId" class="form-label required">
            اسم العميل
            <span>*</span>
          </label>
          <select
            class="form-select form-control"
            id="clientsId"
            name="clientsId"
            v-model.number="body.clientsId"
            :class="{ 'is-invalid': isInvalid && !body.clientsId }"
            required
          >
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="body" class="form-label required">
            البيان
            <span>*</span>
          </label>

          <Editor
            v-model="body.body"
            :class="{ 'is-invalid': isInvalid && !body.body }"
            editorStyle="height: 320px"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-align" value=""></button>
                <button class="ql-align" value="center"></button>
                <button class="ql-align" value="right"></button>
                <button class="ql-align" value="justify"></button>
              </span>
            </template>
          </Editor>
        </div>

        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            :disabled="disabledAdd"
            @click="update()"
            v-if="id"
          />
          <Button
            v-else
            label="اضافة جديد"
            icon="pi pi-plus"
            v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const user = JSON.parse(localStorage.admin);

    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        date: `${ye}-${mo}-${da}`,
        taskTime: d,
        usersId: user.id,
        clientsId: null,
        body: null,
        warehouseId: null,
      },
      id: null,
      warehouseList: [],
      clientsList: [],

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (
        this.body.date &&
        this.body.clientsId &&
        this.body.warehouseId &&
        this.body.body &&
        this.body.taskTime
      ) {
        this.disabledAdd = true;
        this.$http.post(`purchases`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/sales/purchases');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (
        this.body.date &&
        this.body.clientsId &&
        this.body.warehouseId &&
        this.body.body &&
        this.body.taskTime
      ) {
        this.disabledAdd = true;

        this.$http.put(`purchases/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/sales/purchases');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('purchasesAdd')) {
      this.$router.push('/admin/');
    }
    const user = JSON.parse(localStorage.admin);

    this.$http.get(`warehouse`).then((res) => {
      for (const item of res.data) {
      }
      this.warehouseList = res.data.filter((itm) =>
        itm.users.find((u) => u.id == user.id),
      );
    });

    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`purchases/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.clientsId = res.data.clientsId.code;
          this.body.warehouseId = res.data.warehouseId.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style>
.firstSupport label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
